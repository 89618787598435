import { createWebHashHistory, createRouter } from 'vue-router'

const routes = [
  { path: '/', component: () => import('../pages/home/home.vue') },
  {
    path: '/f',
    component: () => import('../pages/batch-fill/batch-fillstudent/batch-fillstudent.vue'),
  },
  {
    path: '/g',
    component: () => import('../pages/batch-fill/batch-filltimesfee/batch-filltimesfee.vue'),
  },
  {
    path: '/h',
    component: () => import('../pages/batch-fill/batch-filldatefee/batch-filldatefee.vue'),
  },
  {
    path: '/c',
    component: () => import('../pages/detail-report/course-fee/index.vue'),
  },
  {
    path: '/s',
    component: () => import('../pages/detail-report/student-sign/index.vue'),
  },
  {
    path: '/d',
    component: () => import('../pages/detail-report/student-sign/index-date.vue'),
  },
  {
    path: '/t',
    component: () => import('../pages/detail-report/teacher-perf/index.vue'),
  },
  {
    path: '/xiaoxx-admin',
    component: () => import('../pages/xiaoxx-admin/index.vue'),
  },
  {
    path: '/xiaoxx-admin-live',
    component: () => import('../pages/xiaoxx-admin/live.vue'),
  },
  {
    path: '/xiaoxx-admin-customer-manage',
    component: () => import('../pages/xiaoxx-admin/customer-manage.vue'),
  },
  {
    path: '/permission-setting',
    component: () => import('../pages/permission-setting/index.vue'),
  },
  {
    path: '/permission-person',
    component: () => import('../pages/permission-setting/person.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
